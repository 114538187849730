@import './utils/variables.css';
@import "tailwindcss/base";
@import './base/reset.css';
@import './base/animations.css';
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import './utils/reCaptcha.css';
@import './utils/google-one-tap-widget.css';
@import './components/brief-color-picker.css';
@import './components/react-international-phone.css';
@import './components//html-string-renderer.css';
